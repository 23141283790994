<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="结算站点" prop="siteId">
                <el-select v-model="query.siteId" filterable placeholder="请选择站点" clearable @change="selectSite">
                    <el-option v-for="item in siteList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="核销日期" >
                <el-date-picker
                    v-model="dateList"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    @change="selectDate"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="结算编号" align="center" prop="id" />
            <el-table-column label="结算站点" align="center" prop="siteName" />
            <el-table-column label="结算单数" align="center" prop="orderNum" />
            <el-table-column label="商品总数" align="center" prop="goodNum" />
            <el-table-column label="结算人" align="center" prop="userName" />
            <el-table-column label="结算时间" align="center" prop="createTime" />
            
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />

    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    data() {
        return {
            // 列表
            list: [],
            itemList: [],
            // 类型
            dictType: [],
            // 总条数
            total: 0,
            // 遮罩层
            loading: false,
            // 查询
            query: {
                current: 1,
                size: 10,
                siteId: null,
                startDate: '',
                endDate: '',
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 弹窗控制器
            dialogVisible: false,
            // 站点信息
            siteList: [],
            // 日期
            dateList: [],
        }
    },
    created() {
        this.getList()
        this.getSiteList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.settleInfoList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 获取站点数据
        getSiteList() {
            api.getSiteList().then(res => {
                this.siteList = res
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.query =  {
                current: 1,
                size: 10,
                siteId: null,
                startDate: '',
                endDate: '',
            }
             this.getList()   
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        // 查看提现明细
        handleView(data) {
            this.dialogVisible = true
        },
        /** 选择日期 */
        selectDate(e) {
            console.log(e);
            if (e.length > 0) {
                this.query.current = 1
                this.query.startDate = e[1]
                this.query.endDate = e[0]
                this.getList()
            }else {
                this.query.current = 1
                this.query.startDate = ''
                this.query.endDate = ''
                this.getList()
            }
        },
        // 选择站点
        selectSite(e) {
            this.query.siteId = e
            this.getList()
        },
    }
}

</script>